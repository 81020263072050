import React from "react";

const Dashboard = () => {
  return (
    <div className="mt-5">
      <div className="flex justify-center items-center font-bold text-[30px] dark:text-white">
        Selamat Datang di Dashboard Admin
      </div>
    </div>
  );
};

export default Dashboard;
