import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const CardCarousel = ({ id, url, title, type, onDelete }) => {
  const navigate = useNavigate();
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleteCarouselId, setDeleteCarouselId] = useState(null);

  const handleEditClick = () => {
    navigate(`/admin/edit-carousel/${id}`);
  };

  const handleDeleteClick = () => {
    setShowDeletePrompt(true);
    setDeleteCarouselId(id);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `/api_w/carousels/${deleteCarouselId}`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            db_name: process.env.REACT_APP_DB_NAME,
          },
        }
      );
      onDelete(deleteCarouselId);
      toast.success("Berhasil Hapus Carousel");
    } catch (error) {
      toast.error(
        "Error Hapus Carousel: " +
          (error.response?.data?.message || error.message)
      );
    } finally {
      setShowDeletePrompt(false);
    }
  };

  const handleCancelDelete = () => {
    setShowDeletePrompt(false);
  };

  const renderMedia = () => {
    if (type === "image") {
      return (
        <img
          className="h-[150px] w-auto object-cover"
          src={url}
          alt={title}
          onClick={handleEditClick}
        />
      );
    } else if (type === "video") {
      const youtubeMatch = url.match(
        /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
      );
      const gdriveMatch = url.match(/drive\.google\.com\/file\/d\/([^\/]+)\//);

      let videoUrl = url;
      if (youtubeMatch) {
        videoUrl = `https://www.youtube.com/embed/${youtubeMatch[1]}`;
      } else if (gdriveMatch) {
        videoUrl = `https://drive.google.com/file/d/${gdriveMatch[1]}/preview`;
      }

      return (
        <iframe
          className="h-[150px] w-full object-cover"
          src={videoUrl}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    }
    return null;
  };

  return (
    <div className="m-2 overflow-hidden rounded bg-white shadow-lg dark:bg-gray-800">
      {renderMedia()}
      <div className="px-6 py-4">
        <div
          className="mb-2 text-xl font-bold dark:text-white cursor-pointer"
          onClick={handleEditClick}
        >
          {title}
        </div>
        <div className="mb-2 flex items-center justify-end">
          <button
            onClick={handleDeleteClick}
            className="mr-2 transform text-red-500 hover:scale-110 hover:text-red-600"
          >
            <FaTrash className="h-4 w-4" />
          </button>
        </div>
      </div>

      {showDeletePrompt && (
        <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50">
          <div className="rounded bg-white p-4 shadow-md">
            <h2 className="mb-4 text-lg font-semibold">
              Konfirmasi Hapus Carousel
            </h2>
            <p className="mb-4">
              Apakah anda yakin akan menghapus carousel ini?
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCancelDelete}
                className="rounded bg-gray-500 py-2 px-4 text-white"
              >
                Batal
              </button>
              <button
                onClick={handleConfirmDelete}
                className="rounded bg-red-500 py-2 px-4 text-white"
              >
                Hapus
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardCarousel;
