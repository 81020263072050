import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { toast } from "react-toastify";

const VisiMisi = () => {
  const [visi, setVisi] = useState("");
  const [idVisi, setIdVisi] = useState("");
  const [titleVisi, setTitleVisi] = useState("");
  const [imageUrlVisi, setImageUrlVisi] = useState("");
  const [videoUrlVisi, setVideoUrlVisi] = useState("");
  const [misi, setMisi] = useState("");
  const [idMisi, setIdMisi] = useState("");
  const [titleMisi, setTitleMisi] = useState("");
  const [imageUrlMisi, setImageUrlMisi] = useState("");
  const [videoUrlMisi, setVideoUrlMisi] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const visionResponse = await axios.get(`/api_w/abouts?title=Visi`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const missionResponse = await axios.get(`/api_w/abouts?title=Misi`, {
          headers: {
            db_name: process.env.REACT_APP_DB_NAME,
          },
        });
        const vision = visionResponse.data.data[0];
        const mission = missionResponse.data.data[0];

        //data visi
        setIdVisi(vision?.id || "");
        setTitleVisi(vision?.title || "");
        setVisi(vision?.body || "");
        setImageUrlMisi(mission?.image_url || "");
        setVideoUrlMisi(mission?.video_url || "");

        //data misi
        setIdMisi(mission?.id || "");
        setTitleMisi(mission?.title || "");
        setMisi(mission?.body || "");
        setImageUrlVisi(vision?.image_url || "");
        setVideoUrlVisi(vision?.video_url || "");
      } catch (error) {
        toast.error(error.response?.data?.message || error.message);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/admin/edit-visi-misi/${id}`);
  };

  const renderVideo = (url) => {
    const youtubeMatch = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    const gdriveMatch = url.match(/drive\.google\.com\/file\/d\/([^\/]+)\//);

    let videoUrl = url;
    if (youtubeMatch) {
      videoUrl = `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    } else if (gdriveMatch) {
      videoUrl = `https://drive.google.com/file/d/${gdriveMatch[1]}/preview`;
    }

    return (
      <div className="flex justify-center">
        <iframe
          className="mt-4 w-80 h-48"
          src={videoUrl}
          title="Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  return (
    <div className="mx-auto mt-8 p-5">
      <div className="relative mx-auto mb-4 w-full rounded-lg border border-gray-600 bg-gray-200 p-6">
        <h2 className="mb-4 flex items-center justify-center text-center text-xl font-bold uppercase">
          {titleVisi}
        </h2>
        {imageUrlVisi && (
          <div className="flex justify-center">
            <img src={imageUrlVisi} alt="visi-image" className="h-24 w-24" />
          </div>
        )}
        {videoUrlVisi && renderVideo(videoUrlVisi)}
        <div className="mt-4 break-words text-justify">{parse(visi)}</div>
        <FaEdit
          className="absolute top-2 right-2 h-5 w-5 cursor-pointer text-gray-800"
          onClick={() => handleEditClick(idVisi)}
        />
      </div>
      <div className="relative mx-auto mt-10 rounded-lg border border-gray-600 bg-gray-200 p-6">
        <h2 className="mb-4 flex items-center justify-center text-center text-xl font-bold uppercase">
          {titleMisi}
        </h2>
        {imageUrlMisi && (
          <div className="flex justify-center">
            <img src={imageUrlMisi} alt="misi-image" className="h-24 w-24" />
          </div>
        )}
        {videoUrlMisi && renderVideo(videoUrlMisi)}
        <div className="mt-4 break-words text-justify">{parse(misi)}</div>
        <FaEdit
          className="absolute top-2 right-2 h-5 w-5 cursor-pointer text-gray-800"
          onClick={() => handleEditClick(idMisi)}
        />
      </div>
    </div>
  );
};

export default VisiMisi;
