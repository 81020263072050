import React from 'react'
import Header from '../../../components/user/Header';
import Details from '../../../components/user/posts/Details';
import BeritaPopuler from '../../../components/user/posts/BeritaPopuler';
import Footer from '../../../components/user/Footer';

function DetailsPosts() {
  return (
    <>
      <Header />
      <div className="row mt-[50px] flex flex-wrap gap-6 p-5">
        <div className="w-full lg:w-[68%]">
          <Details />
        </div>

        <div className="w-full lg:ml-auto lg:w-[30%]">
          <BeritaPopuler />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default DetailsPosts